<template>
  <div>
    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :btns="btns(this)"
      :search-form="searchForm(this)"
      :data="orderList"
      :options="{ selection: true, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '订单列表'
const btns = _this => [
  {
    label: '导出',
    type: 'primary',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '订单编号',
    prop: 'orderId',
  },
  {
    label: '订单内容',
    prop: 'productName',
  },
  {
    label: '支付方式',
    prop: 'payType',
    type: 'select',
    children: _this.payType.map(item => ({ value: item.key, label: item.value })),
  },
  {
    label: '支付状态',
    prop: 'payStatus',
    type: 'select',
    defaultValue: _this.waitOrderPay,
    children: _this.payStatus.map(item => ({ value: item.key, label: item.value })),
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderId',
    label: '订单编号',
    minWidth: 150,
  },
  {
    label: '订单内容',
    minWidth: 200,
    render: (h, { row }) => {
      return h(
        'div',
        {
          class: 'tableOrderContent',
          domProps: {
            title: row.organizationOrderDetailReqDtos.map(v => v.productName),
          },
        },
        [
          row.organizationOrderDetailReqDtos.map(v => {
            return h('div', [h('div', v.productName)])
          }),
        ],
      )
    },
  },
  {
    prop: 'payAmount',
    label: '实付金额',
    align: 'right',
    minWidth: 100,
    render: (h, { row }) => {
      return h('div', row.payAmount ? '￥' + row.payAmount : '')
    },
  },
  {
    prop: 'orderAmount',
    label: '原价',
    align: 'right',
    minWidth: 120,
    render: (h, { row }) => {
      return h('div', row.orderAmount ? '￥' + row.orderAmount : '')
    },
  },
  {
    prop: 'payTypeName',
    label: '支付渠道',
    minWidth: 120,
  },
  {
    prop: 'createTimeShow',
    label: '创建时间',
    minWidth: 150,
  },
  {
    label: '支付时间',
    prop: 'payTimeShow',
    minWidth: 150,
  },
  {
    prop: 'payStatusName',
    label: '状态',
    minWidth: 100,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleDetail,
  },
  {
    label: '去支付',
    method: _this.handleGoPay,
    isShow: row => {
      return row.payStatusName == '未支付' && row.orderType !== 5 ? true : false
    },
  },
]
const payType = [
  { key: 'ALIPAY', value: '支付宝' },
  { key: 'WXPAY', value: '微信' },
  { key: 'THIRD', value: '公对公' },
  { key: 'BALANCE', value: '余额' },
]
const payStatus = [
  { key: 'SUCCESS', value: '已支付' },
  { key: 'WAIT', value: '未支付' },
  { key: 'REFUND', value: '已退款' },
  { key: 'CLOSE', value: '已取消' },
]
import to from 'await-to'
import { getOrderList, orderListExport } from '@/api/finance'
import TableList from '@/components/TableList'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      tableTitle,
      searchForm,
      columns,
      operates,
      payType,
      payStatus,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
        payStatus: '',
        payType: '',
        orderId: '',
        productName: '',
      },
      orderList: [],
      waitOrderPay: this.$route.query.waitOrderPay || '',
      detailPayStatus: '',
      select: [],
    }
  },
  created() {
    if (this.waitOrderPay !== '') {
      this.pager.payStatus = this.waitOrderPay
    }
    this.getOrderListData()
  },
  methods: {
    async getOrderListData() {
      this.loading = true
      const [res, err] = await to(getOrderList({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.orderList = res.data.list
      this.pager.total = res.data.total
    },
    onSearch(params) {
      this.pager.orderId = params.orderId
      this.pager.productName = params.productName
      this.pager.payType = params.payType
      this.pager.payStatus = params.payStatus
      this.detailPayStatus = params.payStatus
      this.pager.current = 1
      this.getOrderListData()
    },
    handleGoPay(row) {
      this.$router.push({
        path: '/finance/detail',
        query: { id: row.id, current: this.pager.current, payStatus: this.detailPayStatus },
      })
    },
    handleDetail(row) {
      this.$router.push({
        path: '/finance/detail',
        query: { id: row.id, current: this.pager.current },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getOrderListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getOrderListData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    async handleExport() {
      let orderIds = []
      this.select.map(v => orderIds.push(v.orderId))
      const [res, err] = await to(orderListExport({ ...this.pager, orderIds }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '订单列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style scoped lang="scss">
.tableList ::v-deep .el-table tr .tableOrderContent {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //控制行数
  overflow: hidden;
  cursor: pointer;
}
</style>
