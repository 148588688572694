/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2022-02-14 10:21:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 11:05:10
 */
import config from './config/finance'
import axios from '@/utils/request'
export const getOrderList = params => axios.post(config.orderList, params) // 订单列表
export const getIncomeList = params => axios.post(config.incomeList, params) // 收入列表
export const getIncomeDownLoad = params => axios.post(config.downLoad, params) //导出收入明细
export const continuePay = params => axios.post(config.continuePay, params) //余额继续支付
export const orderExport = params =>
  axios.post(config.orderExport, params, { responseType: 'blob' }) // 订单导出
export const orderListExport = params =>
  axios.post(config.orderListExport, params, { responseType: 'blob' }) // 订单导出
