/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2022-02-14 10:21:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 11:05:26
 */
export default {
  orderList: '/order/identify/adminOrder/queryOrder', // 订单列表
  incomeList: '/order/identify/adminOrder/findSerial', // 收入列表
  downLoad: '/order/identify/adminOrder/downLoadSerial', //下載收支
  continuePay: '/order/identify/orgOrder/enterprise/balancePayContinue', //余额继续支付
  orderExport: '/order/identify/adminOrder/export', //导出订单
  orderListExport: '/order/identify/adminOrder/exportOrder', //导出订单
}
